<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="12">
          <v-autocomplete
            v-model="shozoku"
            :items="shozokus"
            outlined
            multiple
            clearable
            :rules="[Rules.Required]"
            label="事業所"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row></v-row>
    </v-form>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";

export default {
  name: "ShozokuAddDialog",
  mixins: [Common, Dialog, Forms],
  computed: {
    Title() {
      return "事業所追加";
    }
  },
  data() {
    return {
      shozokus: [],
      shozoku: []
    };
  },
  methods: {
    close(result) {
      this.resolve(result);
      this.$close();
    },
    complete() {
      console.log("complate", this.shozoku);
      if (!this.$refs.form.validate()) return;
      const result = [];
      this.shozoku.forEach(u => {
        const filter = this.shozokus.filter(e => e.code === u);
        result.push(filter[0]);
      });
      this.$close(result);
    }
  },
  created() {
    console.log("created", this.args);
    const args = this.args;
    this.shozokus = args.shozokus;
  }
};
</script>

<style></style>
